import { createApp, App } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import { createPinia } from 'pinia'
import { createApplication } from '@/app'

import '@/utils/ui'
import 'vue-final-modal/style.css'
import "flag-icons/css/flag-icons.min.css"
import './styles/index.sass'
import 'swiper/swiper-bundle.css'
import 'swiper/css/effect-fade'
import 'swiper/css/thumbs'
import 'vue-toast-notification/dist/theme-bootstrap.css'
import 'floating-vue/dist/style.css'

export const app = createApplication({
  createApp, createRouter, createWebHistory, createPinia,
})

window.app = app

declare global {
  interface Window {
    IS_AUTH: number | false
    IS_SUPER: number | false
    ACCESS_TOKENS: string[]
    ALL_ACCESS_TOKENS: Record<string, any>
    // USER_PERMISSIONS: any
    // ALL_PERMISSIONS: any
    google: {
      maps: any
    }
    current_city: {
      title: string,
      location: {
        lng: number,
        lat: number,
      }
    },
    initMap: () => void
    language: string
    languages: Record<string, string>[]
    site_title: string
    show_site_title: boolean
    default_language: string
    current_language: string
    available_languages: { id: string, title: string }[] 
    country_code: string

    django: {
      catalog: {
        [key: string]: string
      }
    }
    app: App
    captcha_key: string
    GOOGLE_MAPS_KEY: string
    login_url: string
    registration_url: string
    comparisons_url: string
    favicon_url: string
    favorites_url: string
    comparison_url: string
    user_agreement: string
    owner_catalog_url: string
    privacy_policy: string
    maps_key: string
    logo_cabinet: string
    cabinet_url: string
    default_currency: string | null
    available_currencies: string[]
  }
}

