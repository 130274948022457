import { defineComponent as _defineComponent } from 'vue'
import { ref, type Ref, nextTick, watch } from 'vue'


const __default__ = {
  name: 'SearchTrigger'
}


export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
  isSearchPage: {
    default: false,
    type: Boolean,
  },
  hideTabs: {
    default: false,
    type: Boolean,
  },
  defaultTab: {
    default: null,
    type: String,
  }
},
  setup(__props, { expose: __expose }) {
  __expose();


const props = __props

const show: Ref<boolean> = ref(true)
const input: Ref = ref(null)
const search: Ref = ref(null)
const fieldInFocus = ref(false)

const setFocus = () => {
  fieldInFocus.value = true
}

const removeFocus = async () => {
  setTimeout(() => {
    fieldInFocus.value = false
  }, 100)
}

const focus = () => {
  show.value = false
  nextTick(() => {
    setTimeout(() => {
      input.value.focus()
    }, 300)
  })
}

const unfocus = () => {
  show.value = !show.value
}

const queryUpdateHandler = query => {
  search.value = query
}

watch(show, (newVal) => {
  const mainContent = document.querySelector('.main-content')

  document.body.classList.toggle('is-overflow', !newVal)

  if (props.isSearchPage) {
    mainContent.classList.toggle('is-search-page', !newVal)
  }
})

const __returned__ = { props, show, input, search, fieldInFocus, setFocus, removeFocus, focus, unfocus, queryUpdateHandler, ref, nextTick, watch }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})